<template>
  <div>
    <vue-autosuggest
      ref="autocomplete"
      v-model="query"
      :suggestions="suggestions"
      :input-props="inputProps"
      :section-configs="sectionConfigs"
      :render-suggestion="renderSuggestion"
      :get-suggestion-value="getSuggestionValue"
      @input="fetchResults"
    />
    <input type="hidden" name="forma_id" id="buscar-forma_id">
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import {
  BCard, BAvatar, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from '@axios'

export default {
  components: {
    VueAutosuggest,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton,
  },
  data() {
    return {
      // codeAjax,
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      photosUrl: '/servicio-salud/farmacia/forma',
      inputProps: {
        id: 'autosuggest__input__forma__ajax',
        placeholder: 'Ingresar código o nombre...',
        class: 'form-control',
        name: 'forma_display',
      },
      suggestions: [],
      sectionConfigs: {
        forma: {
          limit: 30,
          label: 'Forma',
          onSelected: ({ item }) => {
            this.selected = item
            this.setValueInput(item.id);
          },
        }
      },
    }
  },
  methods: {
    setValueInput(value) {
      const input = document.getElementById('buscar-forma_id');
      input.value = value;
    }, 
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const fetchApi = this.$http.get(`${this.photosUrl}?querySearch=${query}&perPage=30`)

        fetchApi.then(({ data }) => {
          this.selected = null;
          this.setValueInput(null);
          this.suggestions = [{ name: 'forma', data : data.data }];
        }).catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.error}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        });
      }, this.debounceMilliseconds)
    },
    renderSuggestion({ item }) {
      return (
        <div>{item.nombre}</div>
      )
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return `${item.nombre}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
