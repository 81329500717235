<template>
  <form @submit="save" id="form-create-medicamento">
    <div class="card">
      <div class="card-header">
        <div class="row justify-content-end align-items-center pl-1 pb-2">
          <a class="btn btn-light btn-sm right" href="/farmacia">
            Volver Atrás
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label><b>Código</b> <b class="text-danger">*</b></label>
              <input type="text" name="cod_prod" class="form-control" v-model="data.code_prod">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Nombre</b> <b class="text-danger">*</b></label>
              <input type="text" name="nombre" class="form-control" v-model="data.nombre">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>¿Receta obligatoria?</b> <b class="text-danger">*</b></label>
              <select class="form-control" name="requiere_receta" v-model="data.requiere_receta">
                <option :value="0">No</option>
                <option :value="1">Si</option>
              </select>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Código Barras</b> <b class="text-danger">*</b></label>
              <input type="text" name="cod_barras" class="form-control" v-model="data.cod_barras">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Concentración</b> <b class="text-danger">*</b></label>
              <input type="text" name="concentracion" class="form-control" v-model="data.concentracion">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Forma</b> <b class="text-danger">*</b></label>
              <BuscarForma/>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Presentación</b> <b class="text-danger">*</b></label>
              <BuscarPresentacion/>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Laboratorio</b> <b class="text-danger">*</b></label>
              <BuscarLaboratorio/>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Tipo</b> <b class="text-danger">*</b></label>
              <BuscarTipo />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Facciones</b> <b class="text-danger">*</b></label>
              <input type="number" name="fracciones" class="form-control" v-model="data.fracciones">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Vencimiento Reg. Sanitario</b> <b class="text-danger">*</b></label>
              <input type="date" name="vencimiento_reg_sanitario" class="form-control" v-model="data.vencimiento_reg_sanitario">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>N° Reg. Sanitario</b> <b class="text-danger">*</b></label>
              <input type="text" name="n_reg_sanitario" class="form-control" v-model="data.n_reg_sanitario">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Precio Pre. venta</b> <b class="text-danger">*</b></label>
              <input type="number" name="precio_venta" class="form-control" step="any" v-model="data.precio_venta">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label><b>Estado</b> <b class="text-danger">*</b></label>
              <select name="estado" class="form-control" v-model="data.estado">
                <option :value="1">Activo</option>
                <option :value="0">Inactivo</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <button class="btn btn-primary">Guardar Datos</button>
      </div>
    </div>
  </form>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
</style>


<script>
import BuscarPresentacion from "../presentacion/buscar-presentacion.vue";
import BuscarForma from "../forma/buscar-forma.vue";
import BuscarTipo from "../tipo/buscar-tipo.vue";
import BuscarLaboratorio from "../laboratorio/buscar-laboratorio.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const dataDefault = {
  cod_prod: '',
  nombre: '',
  requiere_receta: 0,
  cod_barras: '',
  concentracion: '',
  fracciones: '',
  vencimiento_reg_sanitario: '',
  n_reg_sanitario: '',
  precio_venta: 0,
  estado: 1
}

export default {
  components: {
    BuscarPresentacion,
    BuscarForma,
    BuscarTipo,
    BuscarLaboratorio
  },
  data: () => {
    return {
      data: dataDefault
    }
  },
  methods: {
    save(e) {
      e.preventDefault();

      const form = document.getElementById('form-create-medicamento');
      const payload = new FormData(form); 

      const fetchApi = this.$http.post(`/servicio-salud/farmacia/producto`, payload);

      fetchApi.then(() =>  {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `EL medicamento se guardó correctamente!`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        // limpiar formulario
        this.data = dataDefault;
      })
      .catch(error => {
        const code = error.response.data.code || 501;
        const mensaje = code == 422 ? "Datos incorrectos" : error.response.data.error || 'Ocurrió un error'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: mensaje,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      })
    },
  }
}

</script>